<template>
    <div class="customer-display-container" :class="{ 'edit' : editMode }">
        <div class="header">
            <label class="title">Kunde {{ inputCustomer.customerNumber }}</label>

            <div class="sylter-display" :class="{ 'active' : customerIsSyltResident }">
                <img src="@img/sylt.png"/>
                <label>SYLTER</label>
            </div>

            <div class="actions">
                <button v-if="editMode" @click="handleCancel()">
                    <fa-icon icon="fa-regular fa-circle-xmark"/>
                    <label>Abbrechen</label>
                </button>
                <button v-if="!editMode" @click="editMode = true">
                    <fa-icon icon="fa-regular fa-pen-to-square"/>
                    <label>Bearbeiten</label>
                </button>
                <button v-if="false" class="delete">
                    <fa-icon icon="fa-regular fa-trash-can"/>
                    <label>Kunde Löschen</label>
                </button>
            </div>
        </div>

        <div class="customer-info-content">

            <div class="info-entry">
                <div class="customer-role-container">
                    <button class="customer-role" :class="{'highlight': customerIsNaturalPerson}" :disabled="!editMode" @click="setCustomerRoleToNaturalPerson">
                        <label>Person</label>
                    </button>
                    <button class="customer-role" :class="{'highlight': customerIsCompany}" :disabled="!editMode" @click="setCustomerRoleToCompany">
                        <label>Firma</label>
                    </button>
                </div>
                <DropDownInput class="standard-field" label="Anrede" @input="inputCustomer.idSalutation=$event?.displayid" v-model="selectedSalutation" :disabled="!editMode" :optionList="salutations"/>
                <DropDownInput class="standard-field" label="Titel" v-if="customerIsNaturalPerson" @input="inputCustomer.idTitle=$event?.displayid" v-model="selectedTitle" :disabled="!editMode" :optionList="titles"/>
                <TextInput class="standard-field" label="Rechnungsinformation" v-if="customerIsCompany" v-model="inputCustomer.invoiceInfo" :disabled="!editMode"/>
            </div>

            <div class="info-entry bottom">
                <TextInput class="standard-field" label="Vorname" v-if="customerIsNaturalPerson" v-model="inputCustomer.firstName" :disabled="!editMode" />
                <TextInput class="standard-field" :label="labelLastName" v-model="inputCustomer.lastName" :disabled="!editMode" />
                <TextInput class="standard-field" label="Geburtsdatum" v-if="customerIsNaturalPerson" v-model="inputCustomer.birthdate" :disabled="!editMode" type="date"/>
                <TextInput class="standard-field" label="Ansprechpartner" v-if="customerIsCompany" v-model="inputCustomer.contactPerson" :disabled="!editMode"/>
            </div>

            <div class="info-entry">
                <div class="street-house-nr">
                    <TextInput class="street" label="Straße" v-model="inputCustomer.street" :disabled="!editMode"/>
                    <TextInput class="house-nr" label="Nr." v-model="inputCustomer.houseNumber" :disabled="!editMode"/>
                </div>
                <div class="postalcode-city">
                    <TextInput class="postalcode" label="PLZ" v-model="inputCustomer.postalCode" :disabled="!editMode"/>
                    <TextInput class="city" label="Wohnort" v-model="inputCustomer.city" :disabled="!editMode"/>
                </div>
                <DropDownInput class="standard-field" label="Land" @input="inputCustomer.countryCode=$event?.original.countryCode" v-model="selectedCountry" :disabled="!editMode" :option-list="countries"/>
            </div>

            <div class="info-entry">
                <TextInput class="standard-field" label="E-Mail-Adresse" v-model="inputCustomer.email" :disabled="!editMode" />
                <TextInput class="standard-field" label="Telefon Privat" v-model="inputCustomer.phonePrivate" :disabled="!editMode" />
                <TextInput class="standard-field" label="Telefon Mobil" v-model="inputCustomer.phoneMobile" :disabled="!editMode" />
            </div>

            <div class="info-entry double">
                <div class="payment-method-container">
                    <label class="payment-method-label">Bezahlart:</label>
                    <button class="payment-method" :class="{'highlight': inputCustomer.paymentMethod === 'CASH'}" :disabled="!editMode" @click="inputCustomer.paymentMethod = 'CASH'">
                        <label>Bar</label>
                    </button>
                    <button class="payment-method" :class="{'highlight': inputCustomer.paymentMethod === 'DIRECT_DEBIT'}" :disabled="!editMode" @click="inputCustomer.paymentMethod = 'DIRECT_DEBIT'">
                        <label>Lastschrift</label>
                    </button>
                    <button class="payment-method" :class="{'highlight': inputCustomer.paymentMethod === 'INVOICE'}" :disabled="!editMode" @click="inputCustomer.paymentMethod = 'INVOICE'">
                        <label>Rechnung</label>
                    </button>
                </div>
            </div>
            <div class="info-entry double">
                <TextInput v-if="inputBankAccountHolder" @keypress="filterInputForIban" class="standard-field iban" label="IBAN" v-model="formatIbanInput" :disabled="!editMode" :error="!isIbanValid" :errorText="FIELD_REQUIRED"/>
                <TextInput v-if="inputBankAccountHolder" class="standard-field bic" label="BIC" v-model="inputBankAccountHolder.bic" :disabled="!editMode"/>
            </div>
            
            <div class="info-entry double" >
                <div class="newsletter-container">
                    <label class="newsletter-label">Newsletter:</label>
                    <button class="promotion-action" :class="{ 'highlight': inputCustomer.promotionActions?.mailPermitted }" :disabled="!editMode" @click="inputCustomer.promotionActions.mailPermitted = !inputCustomer.promotionActions.mailPermitted">
                        <label>Post</label>
                    </button>
                    <button class="promotion-action" :class="{ 'highlight': inputCustomer.promotionActions?.phonePermitted }" :disabled="!editMode" @click="inputCustomer.promotionActions.phonePermitted = !inputCustomer.promotionActions.phonePermitted">
                        <label>Telefon</label>
                    </button>
                    <button class="promotion-action" :class="{ 'highlight': inputCustomer.promotionActions?.emailPermitted }" :disabled="!editMode" @click="inputCustomer.promotionActions.emailPermitted = !inputCustomer.promotionActions.emailPermitted">
                        <label>E-Mail</label>
                    </button>
                </div>
            </div>
        </div>

        <div class="footer">
            <button v-if="showCostCoverages" @click="openCostCoveragePopUp">
                Kostenübernahme
            </button>
            <button v-if="editMode&&(!newCustomerMode)" class="save highlight" :disabled="!isValid" @click="saveCustomerData()">
                <fa-icon icon="fa-regular fa-floppy-disk"/>
                <label>Änderungen speichern</label>
            </button>
            <button v-if="newCustomerMode" class="save highlight" :disabled="!isValid" @click="createNewCustomer()">
                <fa-icon icon="fa-solid fa-plus"/>
                <label>Neu anlegen</label>
            </button>
        </div>
    </div>
    <PopUp :open="showCostCoveragePopUp" title="Kostenübernahmen"  @close="showCostCoveragePopUp = false" class="cost-coverages-popup">
        <CostCoveragesTableWrapper 
            :costCoverages="costCoverages"
            uuidCustomer=""
            :selectable="selectableCostCoverages"
            @select-cost-coverage="handleSelectCostCoverage"
        />
        <template v-slot:actions>
            <!-- no buttons placeholder -->
            <label></label>
        </template>
    </PopUp>
</template>

<script>

import TextInput from '@elements/TextInput.vue'
import DropDownInput from '@elements/DropDownInput.vue'
import PopUp from '@elements/PopUp.vue'
import CostCoveragesTableWrapper from './CostCoveragesTableWrapper.vue'
//import PopUp from '@components/elements/PopUp.vue'
import axios from 'axios'
import { getBankAccountHolder } from '@lib/middleware-connector'



const EMITS = {
    CLOSE: 'close',
    SAVE: 'save',
    SELECT_COST_COVERAGE: 'select-cost-coverage',  
}

const CUSTOMER_ROLES = {
    NATURAL_PERSON: 'NATURAL_PERSON',
    COMPANY:        'COMPANY'
}

const FIELD_REQUIRED = 'Pflichtfeld'

export default {
    name: 'customer-display',
    emits: [ EMITS.CLOSE, EMITS.SAVE, EMITS.SELECT_COST_COVERAGE ],
    props: {
        customer: {
            type: Object
        },
        showCostCoverages: {
            type: Boolean,
            default: false
        },
        selectableCostCoverages: {
            type: Boolean,
            default: false
        }
    },
    inject: [ 'util', 'globalState'],
    data() {
        return {
            FIELD_REQUIRED,
            editMode: false,
            newCustomerMode: false,
            selectedSalutation: undefined,
            selectedTitle: undefined,
            selectedCountry: undefined,
            inputCustomer: {},
            backupCustomer: {},
            inputBankAccountHolder: {},
            backupBankAccountHolder: {},
            showCostCoveragePopUp: false,
            costCoverages: []
        }
    },
    computed: {
        webcamPlayer() {
            return document.querySelector('#webcam-stream')
        },
        salutations() {
            return this.globalState.baseData?.salutations || []
        },
        titles() {
            return this.globalState.baseData?.titles || []
        },
        countries() {
            return this.globalState.baseData?.countries || []
        },
        customerIsSyltResident() {
            return this.customer?.profileSettings?.find(profile => profile.name.toLowerCase().includes('sylter'))
        },
        formatIbanInput: {
            get() {
                return this.inputBankAccountHolder.iban
            },
            set(newValue) {
                this.inputBankAccountHolder.iban = this.util.formatDisplayIban(newValue)
            },
        },
        customerIsCompany() {
            return this.inputCustomer.role === CUSTOMER_ROLES.COMPANY
        },
        customerIsNaturalPerson() {
            return this.inputCustomer.role === CUSTOMER_ROLES.NATURAL_PERSON
        },
        labelLastName() {
            return this.customerIsCompany ? 'Firmenname' : 'Nachname'
        },
        isIbanValid(){
            if ( !this.inputCustomer || !this.inputBankAccountHolder ) { return false }
            if ( this.inputCustomer.paymentMethod === 'DIRECT_DEBIT' ) {
                return !!this.inputBankAccountHolder.iban 
            }
            return true
        },
        isValid() {
            return this.isIbanValid
        },
    },
    watch: {
        customer() {
            this.setCustomerData()
        }
    },
    mounted() {
        this.setCustomerData()
    },
    methods: {
        filterInputForIban(event) {
            if (event.key.match(/[^\da-zA-Z]/g)) {
                event.preventDefault()
            }
        },
        setCustomerRoleToNaturalPerson(){
            this.inputCustomer.role = CUSTOMER_ROLES.NATURAL_PERSON
        },
        setCustomerRoleToCompany(){
            this.inputCustomer.role = CUSTOMER_ROLES.COMPANY
        },
        setCustomerData() {
            if (!this.customer || (this.customer.uuid === '')) {
                this.newCustomerMode = true
                this.editMode = true

                this.inputCustomer.paymentMethod = 'CASH'
                this.inputCustomer.role = CUSTOMER_ROLES.NATURAL_PERSON
                this.inputCustomer.promotionActions = { 
                    mailPermitted: false, 
                    phonePermitted: false, 
                    emailPermitted: false,
                    smsPermitted: false,
                    faxPermitted: false
                }
                
                return
            }

            this.inputCustomer = {...this.customer}
            this.backupCustomer = {...this.inputCustomer}
            this.selectedSalutation = this.salutations.find( entry => entry.displayid === this.inputCustomer.idSalutation)
            this.selectedTitle = this.titles.find( entry => entry.displayid === this.inputCustomer.idTitle)
            this.selectedCountry = this.countries.find( entry => entry.original.countryCode === this.inputCustomer.countryCode)
            this.loadBankAccountHolder(this.customer.uuidBankAccountHolder)
        },

        //save customer and bank account holder
        async saveCustomerData() {
            let saveCustomerUrl = this.util.middleware()
            saveCustomerUrl += '/sylt-customers/customerbankaccountholders'

            // sanitize the iban for the service
            this.inputBankAccountHolder.iban = this.inputBankAccountHolder.iban.trim().replaceAll(' ', '')

            let saveCustomerBody = {
                customer: this.inputCustomer,
                bankAccountHolder: this.inputBankAccountHolder
            }

            return axios.put(saveCustomerUrl, saveCustomerBody)
            .then(response => {
                if (response.status !== 200) {
                    throw response.statusText
                }
                this.editMode = false
                this.inputCustomer = response.data.customer
                this.backupCustomer = {...this.inputCustomer}
                this.inputBankAccountHolder = response.data.bankAccountHolder
                this.backupBankAccountHolder = {...this.inputBankAccountHolder}
                this.$emit(EMITS.SAVE, {...this.inputCustomer})
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
                return null
            })
        },
        //create new customer and bank account holder
        async createNewCustomer() {
            let createNewCustomerUrl = this.util.middleware()
            createNewCustomerUrl += '/sylt-customers/customerbankaccountholders'

            let createNewCustomerBody = {
                newCustomer: {
                    newCustomer: this.inputCustomer,
                    createNewTicketHolder: true
                },
                newBankAccountHolder: this.inputBankAccountHolder
            }

            return axios.post(createNewCustomerUrl, createNewCustomerBody)
            .then(response => {
                if (response.status !== 200) {
                    throw response.statusText
                }
                this.editMode = false
                this.newCustomerMode = false
                this.inputCustomer = response.data.customer
                this.backupCustomer = {...this.inputCustomer}
                this.inputBankAccountHolder = response.data.bankAccountHolder
                this.backupBankAccountHolder = {...this.inputBankAccountHolder}
                this.$emit(EMITS.SAVE, {...this.inputCustomer})
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
                return null
            })
        },
        async loadBankAccountHolder(uuidBankAccountHolder){
            return await getBankAccountHolder(uuidBankAccountHolder)
            .then(response => {
                this.inputBankAccountHolder = response
                if(this.inputBankAccountHolder.bic === 'NOTPROVIDED') {
                    this.inputBankAccountHolder.bic = ''
                }

                // use the computed properties setter to format the iban
                this.formatIbanInput = this.inputBankAccountHolder.iban

                this.backupBankAccountHolder = {...this.inputBankAccountHolder}
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
                return null
            })
        },
        async getCostCoverages(uuidCustomer){
            let url = this.util.middleware()
            url += '/costcoverage'
            
            return axios.get(url, {params: {externalReference: uuidCustomer}})
            .then((response) => {
                return response.data                    
            }).catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        handleSelectCostCoverage(costCoverage){
            this.$emit(EMITS.SELECT_COST_COVERAGE, costCoverage)
            this.showCostCoveragePopUp = false
        },
        handleCancel() {
            if (this.newCustomerMode) this.$emit(EMITS.CLOSE)
            this.editMode = false
            this.inputCustomer = {... this.backupCustomer}
            this.inputBankAccountHolder = {... this.backupBankAccountHolder}
            this.selectedSalutation = this.salutations.find( entry => entry.displayid === this.inputCustomer.idSalutation)
            this.selectedTitle = this.titles.find( entry => entry.displayid === this.inputCustomer.idTitle)
            this.selectedCountry = this.countries.find( entry => entry.original.countryCode === this.inputCustomer.countryCode)
        },
        async initCostCoverages(){
            this.costCoverages = await this.getCostCoverages(this.customer.uuid)
        },
        async openCostCoveragePopUp() {
            await this.initCostCoverages()
            this.showCostCoveragePopUp = true
        }
    },
    components: {
        DropDownInput,
        TextInput,
        PopUp,
        CostCoveragesTableWrapper
    }
}
</script>

<style lang="scss" scoped>

.customer-display-container {
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &.edit {
        background-color: #f2f9fb;
        border-left: 4px solid #ec0016;
        padding-left: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    > .header {
        padding: 8px;
        padding-bottom: 0;
        display: flex;

        > .title {
            margin: 8px;
            font-size: 2em;
            user-select: initial;

            > span {
                font-weight: bold;
            }
        }
        > .sylter-display {
        width: 90px;
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: space-around;
        border: 2px solid #ccc;
        border-radius: 8px;
        padding: 4px;
        color: #ccc;

            > img {
                height: 28px;
                // https://codepen.io/sosuke/pen/Pjoqqp
                filter: invert(83%) sepia(15%) saturate(24%) hue-rotate(314deg) brightness(101%) contrast(82%);
            }

            > label {
                font-size: 1.2em;
                font-weight: bold;
            }

        &.active {
            color: #008000;
            border-color: #008000;

            > img {
                filter: invert(21%) sepia(100%) saturate(4516%) hue-rotate(107deg) brightness(97%) contrast(106%);
            }
        }
    }
        > .actions {
            margin-left: auto;
            display: flex;

            > .delete {
                margin-left: 24px;
            }
        }
    }

    > .customer-info-content {
        margin-top: 0px;
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;

        .info-entry {
            margin-right: 20px;
            width: 200px;
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            align-items: center;
            user-select: initial;

            &.bottom {
                justify-content: flex-end;
            }

            &.double {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
            }

            > .iban {
                width: 58%;
            }

            > .bic {
                width: 38%;
            }

            :deep(input:disabled),
            :deep(select:disabled) {
                color: #000;
            }

            > div {
                width: 100%
            }

            > .street-house-nr {
                display: flex;
                > .street {
                    flex: 16;
                }

                > .house-nr{
                    flex: 4;
                }
            }
            > .postalcode-city {
                display: flex;
                > .postalcode {
                    max-width: 60px;
                    flex: 6;
                }

                > .city{
                    flex: 14;
                }
            }

            > .newsletter-container {
                display: flex;
               
                > .newsletter-label {
                    color: #999;
                    font-size: 1.25em;
                    margin-right: 5px;
                }
                > .promotion-action {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            > .payment-method-container {
                display: flex;
                > .payment-method-label {
                    color: #999;
                    font-size: 1.25em;
                    margin-right: 5px;
                }
                > .payment-method {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            > .customer-role-container {
                display: flex;
                margin-top: 14px;
                margin-bottom: 19px;
                > .customer-role {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

        }
    }

    > .footer {
        display: flex;
        height: 46px;
        width: 100%;
        padding: 8px;
        margin-top: auto;

        > .save {
            margin-left: auto;
        }
    }
}

.cost-coverages-popup {
    :deep(.table){
        height: 600px;
    }
    :deep(.content-wrapper) {
        width: 1200px;
    }
}
</style>